<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * agents Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Manage Agents",
      items: [
        {
          text: "Admin",
        },
        {
          text: "Manage Agents",
          active: true,
        },
      ],
      submitted: false,
      showmodal: false,
      /* data varialbles*/

      agents: {
        firstName: "",
        phone: "",
      },
      agentsData: [],
      loading: false,
    };
  },
  validations: {
    agents: {
      firstName: { required },
      phone: { required },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.showmodal = false;
      }
      this.submitted = false;
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async addAgent() {
      if (this.agents.phone[0] === "0") {
        this.agents.phone = this.agents.phone.replace(this.agents.phone[0], "233");
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/admin/users/invite-agents`,
          {
            invites: [
              {
                firstName: this.agents.firstName,
                phone: this.agents.phone,
              },
            ],
          }
        );
        this.$toast.success(response.data.message);
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
      console.log(this.agents.phone)
      this.agents.firstName = ""
      this.agents.phone = ""
      
    },
    async getAgents() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin/users/list-agents?page=1&limit=50&sortBy`
        );
        this.agentsData = response.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAgent(agentId) {
      console.log(agentId);
      try {
        const confirmationResult = await Swal.fire({
          title: "Are you sure?",
          text: "Please confirm this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, confirm!",
        });

        if (confirmationResult.isConfirmed) {
          console.log(agentId);
          const url = `${process.env.VUE_APP_BASE_URL}/admin/users/delete-agent`;
          const response = await axios.delete(url, {
            data: {
              agentId: agentId,
            },
          });

          if (response.status === 200 || response.status === 201) {
            await Swal.fire("Success!", "Agent Deleted", "success");

            // Redirect after Swal is closed
            window.location = "/admin/agents";
          } else {
            Swal.fire("Error!", "An error occurred!", "error");
          }
        }
      } catch (error) {
        Swal.fire("Error!", "An error occurred!", "error");
      }
    },
  },
  mounted() {
    this.getAgents();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a
                href="javascript:void(0);"
                class="btn btn-success mb-2"
                @click="showmodal = true"
              >
                <i class="mdi mdi-plus mr-2"></i> Add Agent
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>Agent Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Agent Usename/ID</th>
                    <th>Address</th>
                    <th>Date Added</th>
                    <th style="width: 120px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in agentsData" :key="index">
                    <td>
                      {{ item.profile.firstName + " " + item.profile.lastName }}
                    </td>
                    <td>{{ item.profile.email }}</td>
                    <td>{{ item.profile.phone }}</td>
                    <td>{{ item.profile.username }}</td>
                    <td>{{ item.profile.address }}</td>
                    <td>{{ item.profile.createdAt.split("T")[0] }}</td>
                    <td>
                      <a
                        @click.prevent="deleteAgent(item.profile._id)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Add Agent"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit()">
        <div class="form-group">
          <label for="exampleInputEmail1">Enter Agent First Name</label>
          <input
            id="firstName"
            v-model="agents.firstName"
            type="text"
            name="firstName"
            class="form-control"
            placeholder="Enter first name"
            :class="{ 'is-invalid': submitted && $v.agents.firstName.$error }"
          />
          <div
            v-if="submitted && $v.agents.firstName.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.agents.firstName.required">Email is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Enter Phone Number</label>
          <input
            id="phone"
            v-model="agents.phone"
            type="text"
            name="phone"
            class="form-control"
            placeholder="Enter Agent Phone Number"
            :class="{ 'is-invalid': submitted && $v.agents.phone.$error }"
          />
          <div
            v-if="submitted && $v.agents.phone.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.agents.phone.required">Email is required</span>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success" @click="addAgent()">
            Send Invite
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="submitted == true"
            ></span>
          </button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >Cancel
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>
